<template>
      <div class="row">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
                  <div class="row">
                      <div class="col-lg-4 mb-lg-0 mb-2">
                          <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchmedicine" id="searchmedicine" autocomplete="off" v-on:keyup="getRoomList(1)">
                      </div>
                      <div class="col-lg-1"></div>
                      <div class="col-lg-2 mb-lg-0 mb-2">
                          <multiselect
                              v-model="ward"
                              label="name" track-by="name"
                              :options="ward_list"
                              :multiple="false"
                              :close-on-select="true"
                              :clear-on-select="false"
                              :preserve-search="true" placeholder=""
                              :preselect-first="true"
                              :showLabels="false"
                              :allowEmpty="false"
                              @input=getRoomList(1)
                          /></multiselect>
                      </div>
                      <div class="col-lg-2 mb-lg-0 mb-2">
                            <multiselect
                                v-model="occupancy_status"
                                :options="occupancy_status_list"
                                :multiple="false"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :preserve-search="true" placeholder=""
                                :preselect-first="true"
                                :showLabels="false"
                                :allowEmpty="false"
                                @input=getRoomList(1)
                            /></multiselect>
                      </div>
                      <div class="col-lg-3 mb-lg-0 mb-2">
                          <multiselect
                              v-model="room_type"
                              :options="room_type_list"
                              :multiple="false"
                              :close-on-select="true"
                              :clear-on-select="false"
                              :preserve-search="true" placeholder=""
                              :preselect-first="true"
                              :showLabels="false"
                              :allowEmpty="false"
                              @input=getRoomList(1)
                          /></multiselect>
                      </div>
                  </div>
                <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Room Name</th>
                          <th>Type / Ward</th>
                          <th>Floor</th>
                          <th>Capacity</th>
                          <th>Rate (<span v-html="currency_data.currency_symbol"></span>)</th>
                          <!-- <th>Status</th> -->
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(rm,i) in roomLists" :key="i">
                          <td>{{rm.id}}</td>
                          <td>{{rm.name}}</td>
                          <td v-if="rm.ward != null">{{rm.type}} - {{rm.ward.name}}</td>
                          <td v-else>-</td>
                          <td>{{rm.floor_number}}</td>
                          <td>{{rm.capacity}}</td>
                          <td><span v-html="currency_data.currency_symbol"></span> {{rm.rate_per_day}}</td>
                          <!-- <td><label v-bind:class="{'badge badge-success': rm.medicine_status === 'Active','badge badge-danger': med.medicine_status === 'InActive' || med.medicine_status === 'Inactive'}">{{med.medicine_status}}</label></td> -->
                          <td><router-link :to="{name:'addroom',params:{'id':rm.id}}">
                          <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-eye"></i></button></router-link> &nbsp;&nbsp;&nbsp;&nbsp;
                          <button type="button" @click="deleteRoomAPI(rm.id, rm.name)" class="btn btn-outline-secondary btn-rounded btn-icon"><i class="mdi mdi-delete"></i></button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <div v-if="is_API_call_running" class="text-center mt-5">
                  <b-spinner label=""></b-spinner>
                </div>
                <div class="row" v-if="!is_API_call_running && roomLists.length == 0" >
                   
                    <div class="col-lg-12 text-center">
                      <br/><br/><br/>
                      <small class="text-muted">{{ errorMessage }}</small>
                      <br/><br/><br/>
                    </div>
                </div>
                <div class="b-pagination-outer" v-if="listPages > 1">
                  <ul id="border-pagination">
                    <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                    <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getRoomList(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                    <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
    
</template>
  <script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions} from "vuex";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import string from "../../constant/strings.js"
import Swal from 'sweetalert2'
import {get_user_settings } from '../../helper/helper';

export default {
    name:'roomWardListing',
    title: string.PAGE_TITLE_ROOMS_WARDS,
    components: {
      NavBar,
Footer,
      SideBar,
      Multiselect
    },
    data() {
      return {
          roomLists:[],
          currency_data:null,
          is_API_call_running : false,
          listPages:0,
          activePage:1,
          searchtext:'',
          myTimer:null,
          errorMessage : 'No Data Found.',
          room_type_list:['All','ICU', 'MICU', 'SICU', 'WARD', 'Special Room', 'Twin Sharing Room', 'General Room', 'Recovery Room'],
          occupancy_status_list:['All','Vacant', 'Occupied', 'Reserved'],
          ward_list:[],
          room_type: "All",
          occupancy_status: "All",
          ward: {name:"All", id:0},
      }
    },
    mounted() {
      this.getRoomList(this.activePage);
      this.getWardList();
      this.currency_data = get_user_settings()
    },
    methods:{
      ...mapActions("hospital",["getRoomListData", "getIPDWardListData", "deleteRoom"]),
  
      getRoomList(page){
        this.activePage = page;
        clearTimeout(this.myTimer)
        this.myTimer = setTimeout(() => {
          this.is_API_call_running = true;
            this.roomLists = [];
            var bodyFormData = new FormData();
            bodyFormData.append('search_text', this.searchtext);
            bodyFormData.append('page', page);
            bodyFormData.append('total_record', 50);
            bodyFormData.append('type', this.room_type);
            bodyFormData.append('occupancy_status', this.occupancy_status);
            bodyFormData.append('ward_id', this.ward.id);
            this.getRoomListData(bodyFormData).then((response) => {
              this.is_API_call_running = false;
              if (response.response_code == 200) {
                this.roomLists = response.data;
                this.listPages = response.no_of_pages;
              }else{
                this.errorMessage = response.message;
              }
            });
        }, 500)
      },

          previewClick(){
            this.activePage--;
            if(this.activePage >= 1){
              this.getRoomList(this.activePage);
            }else{
              this.activePage = 1;
            }
          },

          nextClick(){
            this.activePage++;
            if(this.activePage <= this.listPages){
              this.getRoomList(this.activePage);
            }else{
              this.activePage = this.listPages;
            }
          },
        getWardList() {
          this.ward_list = [];
          var bodyFormData = new FormData();
          bodyFormData.append('search_text', '');
          bodyFormData.append('page', 1);
          this.getIPDWardListData(bodyFormData).then((response) => {
              if (response.response_code == 200) {
                this.ward_list.push({"id":0, "name":"All"})
                response.data.forEach((obj) => {
                    this.ward_list.push(obj);
                })
              } else {
                this.errorMessage = response.message;
              }
          });
        },
        deleteRoomAPI(id, room_name, index) {
            Swal.fire({
              title: string.LABEL_ARE_YOU_SURE,
              text: string.ALERT_MESSAGE_DELETE_PRE + room_name + string.ROOM,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, delete it!"
            }).then((result) => {
              if (result.isConfirmed) {
                var bodyFormData = new FormData();
                bodyFormData.append('room_id', id);
                this.deleteRoom(bodyFormData).then((response) => {
                    if (response.response_code == 200) {
                        this.$toasted.success(response.message, {duration: 2000,});
                        for (let i = 0; i <= this.roomLists.length;i++){
                            if(id == this.roomLists[i].id) {
                                this.roomLists.splice(i, 1);
                                break;
                            }
                        }
                    } else{
                        this.$toasted.error(response.message, {duration: 2000,});
                    }
                });
              }
            });
        },
    }
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
