<template>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="row">
              <div class="col-lg-4 mb-lg-0 mb-2">
                <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchward"
                  id="searchward" autocomplete="off" v-on:keyup="getWardList(1)">
              </div>
              <div class="col-lg-6"></div>
              <div class="col-lg-2 mb-lg-0 mb-2">
                <multiselect
                    v-model="ward_type"
                    :options="ward_type_list"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true" placeholder=""
                    :preselect-first="true"
                    :showLabels="false"
                    :allowEmpty="false"
                    @input=getWardList(1)
                    /></multiselect>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Capacity</th>
                      <th>Description</th>
                      <!-- <th>Status</th> -->
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(wm, i) in wardLists" :key="i">
                      <td>{{ wm.id }}</td>
                      <td>{{ wm.name }}</td>

                      <td> {{ wm.type }}</td>
                      <td>{{ wm.capacity }}</td>
                      <td>{{ wm.description }}</td>
                      <!-- <td><label v-bind:class="{'badge badge-success': rm.medicine_status === 'Active','badge badge-danger': med.medicine_status === 'InActive' || med.medicine_status === 'Inactive'}">{{med.medicine_status}}</label></td> -->
                      <td><router-link :to="{ name: 'addward', params: { 'id': wm.id } }">
                          <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i
                              class="mdi mdi-eye"></i></button></router-link> &nbsp;&nbsp;&nbsp;&nbsp;
                          <button type="button" @click="deleteWardAPI(wm.id, wm.name)" class="btn btn-outline-secondary btn-rounded btn-icon"><i class="mdi mdi-delete"></i></button>

                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
            <div v-if="is_API_call_running" class="text-center mt-5">
              <b-spinner label=""></b-spinner>
            </div>
            <div class="row" v-if="!is_API_call_running && wardLists.length == 0">

              <div class="col-lg-12 text-center">
                <br /><br /><br />
                <small class="text-muted">{{ errorMessage }}</small>
                <br /><br /><br />
              </div>
            </div>
            <div class="b-pagination-outer" v-if="listPages > 1">
              <ul id="border-pagination">
                <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getRoomList(page)"
                    :class="(activePage && activePage == page) ? 'active' : ''">{{ page }}</a></li>
                <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
              </ul>
            </div>
        </div>
      </div>
    <Footer/>
    </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions } from "vuex";
import Multiselect from 'vue-multiselect';
import string from "../../constant/strings.js"
import Swal from 'sweetalert2'

export default {
  name: 'WardListing',
  title: string.PAGE_TITLE_ROOMS_WARDS,
  components: {
    NavBar,
    SideBar,
    Multiselect
  },
  data() {
    return {
      wardLists: [],
      is_API_call_running: false,
      listPages: 0,
      activePage: 1,
      searchtext: '',
      myTimer: null,
      errorMessage: 'No Data Found.',
      ward_type_list:['All','General', 'Medical', 'Surgical', 'Pediatric'],
      ward_type: "All",
    }
  },
  mounted() {
    this.getWardList(this.activePage);
  },
  methods: {
    ...mapActions("hospital", ["getWardListData", "deleteWard"]),

    getWardList(page) {
      this.activePage = page;
      clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.is_API_call_running = true;
        this.wardLists = [];
        var bodyFormData = new FormData();
        bodyFormData.append('search_text', this.searchtext);
        bodyFormData.append('page', page);
        bodyFormData.append('total_record', 50);
        bodyFormData.append('type', this.ward_type);
        this.getWardListData(bodyFormData).then((response) => {
          this.is_API_call_running = false;
          if (response.response_code == 200) {
            this.wardLists = response.data;
            this.listPages = response.no_of_pages;
          } else {
            this.errorMessage = response.message;
          }
        });
      }, 500)
    },

    previewClick() {
      this.activePage--;
      if (this.activePage >= 1) {
        this.getWardList(this.activePage);
      } else {
        this.activePage = 1;
      }
    },

    nextClick() {
      this.activePage++;
      if (this.activePage <= this.listPages) {
        this.getWardList(this.activePage);
      } else {
        this.activePage = this.listPages;
      }
    },
    deleteWardAPI(id, ward_name, index) {
        Swal.fire({
          title: string.LABEL_ARE_YOU_SURE,
          text: string.ALERT_MESSAGE_DELETE_PRE + ward_name + string.WARD,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            bodyFormData.append('ward_id', id);
            this.deleteWard(bodyFormData).then((response) => {
                if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    for (let i = 0; i <= this.wardLists.length;i++){
                        if(id == this.wardLists[i].id) {
                            this.wardLists.splice(i, 1);
                            break;
                        }
                    }
                } else{
                    this.$toasted.error(response.message, {duration: 2000,});
                }
            });
          }
        });
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
